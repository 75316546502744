<template>
  <DetailTemplate
    :customClass="'opportunity-detail detail-page'"
    v-if="getPermission('opportunity:view')"
  >
    <template v-slot:sidebar-detail>
      <v-card class="h-100" elevation="0">
        <div class="d-flex px-0 flex-wrap align-center">
          <v-chip
            :color="getColor(opportunityArr.status)"
            label
            v-if="opportunityArr.status"
            class="mr-2 my-1 custom-status font-weight-600 custom-grey-border text-uppercase justify-center font-10 text-white"
          >
            <span class="font-size-16 font-weight-500">
              {{ getStatus(opportunityArr.status) }}
            </span>
          </v-chip>
        </div>

        <!--  <div class="mb-5 text-center">
          <div class="mb-1">
            <span class="fw-500">Project Name:</span>
            <span class="font-weight-bold ms-1">Changi Airport T6</span>
          </div>
          <div class="mb-1">
            <span class="fw-500">Opportunity:</span> Arrival T6 Lighting
          </div>
          <div class="mb-1">
            Created Date:
            <v-chip small color="blue" text-color="white"
              ><v-icon small>mdi-click</v-icon>24/08/2024 05:34 PM</v-chip
            >
          </div>
          <div>
            Last Contacted: 20/08/2024
            <span class="blue--text font-weight-bold">(20 days)</span>
          </div>
        </div> -->
        <div class="mt-5 px-0">
          <table class="width-100">
            <tr>
              <th
                colspan="2"
                class="grey lighten-4 font-size-16 color-custom-blue py-1"
              >
                Opportunity Info
              </th>
            </tr>

            <tr>
              <td
                valign="top"
                width="100"
                class="py-1 font-size-18 text-capitalize font-weight-500 text-nowrap"
              >
                Opportunity #
              </td>
              <td valign="top" class="py-1 font-size-18 text-capitalize">
                <template v-if="opportunityArr.barcode">
                  <Barcode :barcode="opportunityArr.barcode"></Barcode
                ></template>
              </td>
            </tr>
            <tr>
              <td
                valign="top"
                width="100"
                class="py-1 font-size-18 text-capitalize font-weight-500 text-nowrap"
              >
                Opportunity Name
              </td>
              <td valign="top" class="py-1 font-size-18 text-capitalize">
                <template v-if="opportunityArr.opportunity_name">{{
                  opportunityArr.opportunity_name
                }}</template>
                <template v-else
                  ><em class="text-muted">No Opportunity Name</em></template
                >
              </td>
            </tr>
            <tr>
              <td
                valign="top"
                width="100"
                class="py-1 font-size-18 text-capitalize font-weight-500 text-nowrap"
              >
                Start Date
              </td>
              <td valign="top" class="py-1 font-size-18 text-capitalize">
                <template v-if="opportunityArr.start_date">{{
                  formatDate(opportunityArr.start_date)
                }}</template>
                <template v-else
                  ><em class="text-muted">No Start Date</em></template
                >
              </td>
            </tr>
            <tr>
              <td
                valign="top"
                width="100"
                class="py-1 font-size-18 text-capitalize font-weight-500 text-nowrap"
              >
                End Date
              </td>
              <td valign="top" class="py-1 font-size-18 text-capitalize">
                <template v-if="opportunityArr.end_date">{{
                  formatDate(opportunityArr.end_date)
                }}</template>
                <template v-else
                  ><em class="text-muted">No End Date</em></template
                >
              </td>
            </tr>

            <tr>
              <td
                valign="top"
                width="100"
                class="py-1 font-size-18 text-capitalize font-weight-500 text-nowrap"
              >
                Customer Purchase Order
              </td>
              <td valign="top" class="py-1 font-size-18 text-capitalize">
                <template v-if="opportunityArr.purchase_order">{{
                  opportunityArr.purchase_order
                }}</template>
                <template v-else
                  ><em class="text-muted"
                    >No Customer Purchase Order</em
                  ></template
                >
              </td>
            </tr>
            <tr>
              <td
                valign="top"
                width="100"
                class="py-1 font-size-18 text-capitalize font-weight-500 text-nowrap"
              >
                Reference #
              </td>
              <td valign="top" class="py-1 font-size-18 text-capitalize">
                <template v-if="opportunityArr.reference">{{
                  opportunityArr.reference
                }}</template>
                <template v-else
                  ><em class="text-muted">No Reference #</em></template
                >
              </td>
            </tr>
            <tr>
              <td
                valign="top"
                width="100"
                class="py-1 font-size-18 text-capitalize font-weight-500 text-nowrap"
              >
                Opportunity Budget
              </td>
              <td valign="top" class="py-1 font-size-18 text-capitalize">
                <template v-if="opportunityArr.budget">
                  {{
                    $accountingJS.formatMoney(opportunityArr.budget)
                  }}</template
                >
                <template v-else
                  ><em class="text-muted">No Opportunity Budget</em></template
                >
              </td>
            </tr>
            <tr>
              <td
                valign="top"
                width="100"
                class="py-1 font-size-18 text-capitalize font-weight-500 text-nowrap"
              >
                Lead Source
              </td>
              <td valign="top" class="py-1 font-size-18 text-capitalize">
                <template v-if="opportunityArr.lead_source">{{
                  opportunityArr.lead_source
                }}</template>
                <template v-else
                  ><em class="text-muted">No Lead Source</em></template
                >
              </td>
            </tr>
            <br />
            <tr v-if="opportunityArr?.description">
              <th
                colspan="2"
                class="grey lighten-4 font-size-16 color-custom-blue py-1"
              >
                Opportunity Description
              </th>
            </tr>
            <tr v-if="opportunityArr?.description">
              <td
                valign="top"
                colspan="2"
                class="py-1 font-size-18 text-capitalize font-weight-500"
                width="100%"
              >
                {{ opportunityArr?.description }}
              </td>
            </tr>

            <br />
            <tr v-if="opportunityArr?.project?.barcode">
              <th
                colspan="2"
                class="grey lighten-4 font-size-16 color-custom-blue py-1"
              >
                Project Info
              </th>
            </tr>
            <tr v-if="opportunityArr?.project?.barcode">
              <td
                valign="top"
                width="100"
                class="py-1 font-size-18 text-capitalize font-weight-500 text-nowrap"
              >
                Project #
              </td>
              <td valign="top" class="py-1 font-size-18 text-capitalize">
                <template v-if="opportunityArr?.project?.barcode">
                  <Barcode
                    color="red"
                    route="project.detail"
                    :barcode="opportunityArr?.project?.barcode"
                    :id="opportunityArr?.project?.id"
                  ></Barcode
                ></template>
                <template v-else
                  ><em class="text-muted">No Project #</em></template
                >
              </td>
            </tr>
            <tr v-if="opportunityArr?.project?.name">
              <td
                valign="top"
                width="100"
                class="py-1 font-size-18 text-capitalize font-weight-500 text-nowrap"
              >
                Project Name
              </td>
              <td valign="top" class="py-1 font-size-18 text-capitalize">
                <template v-if="opportunityArr?.project?.name">{{
                  opportunityArr?.project?.name
                }}</template>
                <template v-else
                  ><em class="text-muted">No Project Name</em></template
                >
              </td>
            </tr>
            <!--  <br />
            <tr>
              <th
                colspan="2"
                class="grey lighten-4 font-size-16 color-custom-blue py-1"
              >
                Final Won Contact
              </th>
            </tr>
            <tr>
              <td
                valign="top"
                width="100"
                class="py-1 font-size-18 text-capitalize font-weight-500 text-nowrap"
              >
                Contact
              </td>
              <td valign="top" class="py-1 font-size-18 text-capitalize">
                <template v-if="opportunityArr.contact_name">{{
                  opportunityArr.contact_name
                }}</template>
                <template v-else
                  ><em class="text-muted">No contact</em></template
                >
              </td>
            </tr>
            <tr>
              <td
                valign="top"
                width="100"
                class="py-1 font-size-18 text-capitalize font-weight-500 text-nowrap"
              >
                Email
              </td>
              <td valign="top" class="py-1 font-size-18 text-capitalize">
                <template v-if="opportunityArr.contact_email"
                  ><v-icon small class="me-1">mdi-email</v-icon
                  >{{ opportunityArr.contact_email }}</template
                >
                <template v-else><em class="text-muted">No email</em></template>
              </td>
            </tr>
            <tr>
              <td
                valign="top"
                width="100"
                class="py-1 font-size-18 text-capitalize font-weight-500 text-nowrap"
              >
                Mobile
              </td>
              <td valign="top" class="py-1 font-size-18 text-capitalize">
                <template v-if="opportunityArr.contact_mobile"
                  ><v-icon small class="me-1">mdi-cellphone</v-icon
                  >{{ opportunityArr.contact_mobile }}</template
                >
                <template v-else
                  ><em class="text-muted">No mobile</em></template
                >
              </td>
            </tr>
            <br />
            <tr>
              <th
                colspan="2"
                class="grey lighten-4 font-size-16 color-custom-blue py-1"
              >
                Final Won Company
              </th>
            </tr>
            <tr>
              <td
                valign="top"
                class="py-1 font-size-18 text-capitalize font-weight-500 text-nowrap"
              >
                Company
              </td>
              <td valign="top" class="py-1 font-size-18 text-capitalize">
                Genic Team
              </td>
            </tr>
            <tr>
              <td
                valign="top"
                class="py-1 font-size-18 text-capitalize font-weight-500"
              >
                Email
              </td>
              <td valign="top" class="py-1 font-size-18">genic@gmail.com</td>
            </tr>

            <tr>
              <td
                valign="top"
                class="py-1 font-size-18 text-capitalize font-weight-500"
              >
                Phone No.
              </td>
              <td class="py-1 font-size-18 text-capitalize">
                +6524578478
              
              </td>
            </tr>

            <tr>
              <td
                valign="top"
                class="py-1 font-size-18 text-capitalize font-weight-500"
              >
                fax
              </td>
              <td valign="top" class="py-1 font-size-18 text-capitalize">
                +6587848787
              </td>
            </tr> -->
          </table>
        </div>
      </v-card>
    </template>
    <template v-slot:header-title>
      <div class="d-flex">
        <template v-if="pageLoading">
          <v-skeleton-loader
            class="custom-skeleton"
            width="400"
            height="28"
            type="text"
          >
          </v-skeleton-loader>
          <v-skeleton-loader
            class="custom-skeleton height-30px my-auto mx-2 custom-skeleton-full-width"
            width="130"
            type="text"
          >
          </v-skeleton-loader>
        </template>
        <template v-else>
          <h1 class="form-title custom-nowrap-ellipsis text-capitalize">
            <!-- {{ getPageTitle }} -->
            {{ opportunityArr?.opportunity_name }}
          </h1>
          <!-- <CustomStatus
            :status="opportunityArr.status"
            endpoint="project/status"
          ></CustomStatus> -->
          <!--   <v-chip
            :color="getColor(opportunityArr.status)"
            label
            v-if="opportunityArr.status"
            class="ml-2"
          >
            <span class="font-size-16 font-weight-500">
              {{ getStatus(opportunityArr.status) }}
            </span>
          </v-chip> -->
        </template>
      </div>
    </template>
    <template v-slot:header-action>
      <template v-if="getPermission('opportunity:update')">
        <template v-if="false">
          <template v-if="isOppStatus">
            <v-btn
              v-if="isAccepted"
              small
              rounded
              color="green darken-4 text-white"
              class="ml-2"
              depressed
              >Accepted
            </v-btn>
            <v-btn
              v-if="isRejected"
              small
              rounded
              color="red darken-4 text-white"
              class="ml-2"
              depressed
              >Rejected
            </v-btn>
            <v-btn
              @click="changeColor('reopen')"
              outlined
              color="grey"
              class="ml-2"
              depressed
              tile
              >Reopen
            </v-btn>
          </template>
          <template v-else>
            <v-btn
              @click="changeColor('accept')"
              color="green darken-4 text-white"
              class="ml-2"
              depressed
              tile
              >Accepted
            </v-btn>
            <v-btn
              @click="changeColor('reject')"
              color="red darken-4 text-white"
              class="ml-2"
              depressed
              tile
              >Rejected
            </v-btn>
          </template>
        </template>
        <template>
          <v-tooltip top content-class="custom-top-tooltip">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :disabled="pageLoading"
                class="mx-2 custom-bold-button white--text"
                color="cyan"
                v-bind="attrs"
                v-on="on"
                v-on:click="updateOpportunity()"
              >
                <v-icon left>mdi-pencil</v-icon> Edit
              </v-btn>
            </template>
            <span>Click here to edit Opportunity</span>
          </v-tooltip>
        </template>
        <v-btn
          :disabled="pageLoading"
          class="mx-2 custom-bold-button white--text"
          color="cyan"
          :to="
            getDefaultRoute('costing.create', {
              query: {
                opportunity: $route.params?.id,
              },
            })
          "
        >
          <v-icon class="me-2" right>mdi-plus</v-icon> Create Costing
        </v-btn>
        <v-menu
          v-if="false"
          style="z-index: 99 !important"
          transition="slide-y-transition"
          bottom
          content-class="custom-menu-list"
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :disabled="pageLoading"
              class="mx-2 custom-bold-button white--text"
              color="cyan"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon class="me-2" right>mdi-plus</v-icon> Add
              <v-icon right>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <template v-for="(more, index) in projectQuoteCosting">
              <v-list-item
                link
                v-on:click="createMoreAction(more.action)"
                :key="index"
                :disabled="more.disabled"
              >
                <v-list-item-icon class="margin-auto-zero mr-3 my-2">
                  <v-icon class="icon-default-blue">{{ more.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="font-weight-500 font-size-14">{{
                  more.title
                }}</v-list-item-title>
              </v-list-item>
            </template>
          </v-list>
        </v-menu>
        <v-menu
          v-if="opportunityArr.status < 4 && false"
          style="z-index: 99 !important"
          transition="slide-y-transition"
          bottom
          content-class="custom-menu-list"
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :disabled="pageLoading"
              class="mx-2 custom-bold-button white--text"
              color="cyan"
              v-bind="attrs"
              v-on="on"
            >
              More... <v-icon right>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <template v-for="(more, index) in projectMoreAction">
              <v-list-item
                link
                v-on:click="updateMoreAction(more.action)"
                :key="index"
                :disabled="more.disabled"
              >
                <v-list-item-icon class="margin-auto-zero mr-3 my-2">
                  <v-icon class="icon-default-blue">{{ more.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="font-weight-500 font-size-14">{{
                  more.title
                }}</v-list-item-title>
              </v-list-item>
            </template>
          </v-list>
        </v-menu>
      </template>
      <v-btn
        class="mx-2 custom-grey-border custom-bold-button"
        v-on:click="goBack"
      >
        <v-icon small left>mdi-keyboard-backspace</v-icon>
        Back
      </v-btn>
    </template>
    <template v-slot:body>
      <div class="step-status mb-2 mt-1" v-if="false">
        <v-tabs
          v-model="statusTab"
          background-color="transparent"
          grow
          color="green"
          active-class="green darken-4 text-white"
          hide-slider
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-tab
                v-bind="attrs"
                v-on="on"
                :class="statusTab <= 4 ? activeTabClass : ''"
              >
                Not Connected (0 Days)</v-tab
              >
            </template>
            <span>Not Connected</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-tab
                v-bind="attrs"
                v-on="on"
                :class="statusTab <= 4 && statusTab >= 1 ? activeTabClass : ''"
              >
                Follow up (0 Days)</v-tab
              >
            </template>
            <span>Follow up</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-tab
                v-bind="attrs"
                v-on="on"
                :class="statusTab <= 4 && statusTab >= 2 ? activeTabClass : ''"
              >
                Meeting (0 Days)</v-tab
              >
            </template>
            <span>Meeting</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-tab
                v-bind="attrs"
                v-on="on"
                :class="statusTab <= 4 && statusTab >= 3 ? activeTabClass : ''"
              >
                Quotation (0 days)</v-tab
              >
            </template>
            <span>Quotation</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-tab
                v-bind="attrs"
                v-on="on"
                :class="statusTab <= 4 && statusTab >= 4 ? activeTabClass : ''"
              >
                Negotiation (0 Days)</v-tab
              >
            </template>
            <span>Negotiation</span>
          </v-tooltip>
        </v-tabs>
      </div>
      <v-tabs
        v-model="opportunityTab"
        background-color="transparent"
        color="cyan"
        class="custom-tab-transparent"
      >
        <template v-for="(tab, index) in tabs">
          <v-tab
            class="font-size-16 font-weight-600 px-8"
            :key="index"
            :href="'#' + tab.key"
            :disabled="tab.disabled"
          >
            <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
              <!--begin::Svg Icon-->
              <inline-svg
                :src="$assetURL('media/custom-svg/' + tab.key + '.svg')"
              />
              <!--end::Svg Icon-->
            </span>
            {{ tab.title }}
          </v-tab>
        </template>
      </v-tabs>
      <perfect-scrollbar
        :options="{ suppressScrollX: true }"
        class="scroll"
        style="height: 84vh; position: relative"
      >
        <v-tabs-items v-model="opportunityTab">
          <v-tab-item
            v-if="getPermission('opportunity:view')"
            :value="'overview'"
          >
            <OverviewCreate
              :opportunityArr="opportunityArr"
              v-on:reload:opportunity="getOpportunity"
            ></OverviewCreate>
          </v-tab-item>
          <v-tab-item
            v-if="getPermission('opportunity:view')"
            :value="'company-contact'"
          >
            <CompanyContactPerson
              :detail="opportunityArr"
              type="project"
            ></CompanyContactPerson>
          </v-tab-item>

          <!-- <v-tab-item value="milestones">
            <Milestones
              :detail="opportunityArr"
              type="project"
              v-on:reloadData="refressData"
            ></Milestones>
          </v-tab-item> -->
          <v-tab-item :value="'visit'" v-if="false">
            <VisitListingTemplate
              is-admin
              visit-type="all"
              :visit-status="0"
              detailRoute="visit.detail"
              internal
              :project-id="
                opportunityArr && opportunityArr.id ? opportunityArr.id : 0
              "
              :customer-id="
                opportunityArr &&
                opportunityArr.customer &&
                opportunityArr.customer.id
                  ? opportunityArr.customer.id
                  : 0
              "
            ></VisitListingTemplate>
          </v-tab-item>
          <v-tab-item v-if="getPermission('task:view')" value="task">
            <Tasks :detail="opportunityArr" type="project"></Tasks>
          </v-tab-item>
          <v-tab-item v-if="getPermission('task:view')" value="timesheet">
            <Timesheets :detail="opportunityArr" type="project"></Timesheets>
          </v-tab-item>
          <v-tab-item value="contract" v-if="getPermission('contract:view')">
            <Contracts :detail="opportunityArr" type="project"></Contracts>
          </v-tab-item>
          <v-tab-item :value="'costing'">
            <ProjectCostings
              :detail="opportunityArr"
              type="opportunity"
              :type-id="opportunityArr?.id"
            ></ProjectCostings>
          </v-tab-item>
          <v-tab-item v-if="getPermission('job:view')" :value="'job'">
            <Tickets :detail="opportunityArr" type="project"></Tickets>
          </v-tab-item>
          <v-tab-item v-if="getPermission('quotation:view')" value="quotation">
            <Quotations
              :detail="opportunityArr"
              type="opportunity"
              :type-id="opportunityArr?.id"
            ></Quotations>
          </v-tab-item>
          <v-tab-item v-if="getPermission('quotation:view')" value="orders">
            <QuotationOrder
              :detail="opportunityArr"
              type="opportunity"
              :type-id="opportunityArr?.id"
            ></QuotationOrder>
          </v-tab-item>
          <v-tab-item
            v-if="getPermission('attachment:view')"
            value="attachment"
          >
            <Attachments
              :images="fileArr"
              :parent="
                opportunityArr && opportunityArr.id ? opportunityArr.id : 0
              "
              type="project"
              :isPageLoading="pageLoading"
            ></Attachments>
          </v-tab-item>
          <v-tab-item
            v-if="getPermission('purchase-order:view')"
            value="purchase-order"
          >
            <PurchaseOrders
              :detail="opportunityArr"
              type="project"
            ></PurchaseOrders>
          </v-tab-item>
          <v-tab-item v-if="getPermission('invoice:view')" :value="'invoice'">
            <Invoices :detail="opportunityArr" type="project"></Invoices>
          </v-tab-item>
          <v-tab-item v-if="getPermission('history:view')" :value="'history'">
            <InternalHistoryDetail
              type="project"
              :type_id="
                opportunityArr && opportunityArr.id ? opportunityArr.id : 0
              "
            ></InternalHistoryDetail>
          </v-tab-item>
        </v-tabs-items>
      </perfect-scrollbar>
    </template>
    <template v-slot:footer> </template>
  </DetailTemplate>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GET, PATCH } from "@/core/services/store/request.module";
import DetailTemplate from "@/view/pages/partials/Detail-Template.vue";
import InternalHistoryDetail from "@/view/pages/partials/Detail/Internal-History-Detail.vue";
import OverviewCreate from "@/view/pages/opportunity/partials/Overview-Create";
import Tasks from "@/view/pages/partials/Detail/Tasks";
// import Milestones from "@/view/pages/partials/Detail/Milestones";
import Attachments from "@/view/pages/partials/Detail/Attachments";
import Timesheets from "@/view/pages/partials/Detail/Timesheets";
import Tickets from "@/view/pages/partials/Detail/Tickets";
import Quotations from "@/view/pages/partials/Detail/Quotation";
import QuotationOrder from "@/view/pages/partials/Detail/Orders";
import CompanyContactPerson from "@/view/pages/partials/Detail/CompanyContactPerson";
import ProjectCostings from "@/view/pages/partials/Detail/ProjectCosting";
import PurchaseOrders from "@/view/pages/partials/Detail/PurchaseOrder";
import Contracts from "@/view/pages/partials/Detail/Contract";
import Invoices from "@/view/pages/partials/Detail/Invoice";
import VisitListingTemplate from "@/view/pages/visit/Visit-Listing-Template";
import Barcode from "@/view/pages/partials/Barcode.vue";

/* import CustomStatus from "@/view/pages/partials/CustomStatus.vue"; */

export default {
  mixins: [CommonMixin, ValidationMixin],
  name: "opportunity-detail",
  title: "Opportunity",
  data() {
    return {
      project: 0,
      opportunity: 0,
      statusTab: 0,
      isOppStatus: false,
      activeTabClass: "green darken-4 text-white v-tab--active",
      pageLoading: true,
      customerActivated: null,
      opportunityArr: {},
      fileArr: [],
      tabs: [
        {
          title: "Overview",
          icon: "mdi-phone",
          key: "overview",
          disabled: false,
        },
        /*   {
          title: "Company & Contact Person",
          icon: "mdi-account-multiple",
          key: "company-contact",
          disabled: false,
        }, */
        // {
        //   title: "Milestones",
        //   icon: "mdi-phone",
        //   key: "milestones",
        //   disabled: false,
        // },
        //{
        //title: "Visit",
        //icon: "mdi-credit-card",
        // key: "visit",
        // disabled: false,
        //},

        {
          title: "Project Costings",
          icon: "mdi-credit-card",
          key: "costing",
          disabled: false,
        },
        {
          title: "Quotations",
          icon: "mdi-account-multiple",
          key: "quotation",
          disabled: false,
        },
        {
          title: "Orders",
          icon: "mdi-account-multiple",
          key: "orders",
          disabled: false,
        },
        {
          title: "Attachments",
          icon: "mdi-credit-card",
          key: "attachment",
          disabled: false,
        },
        /* {
          title: "Tasks",
          icon: "mdi-credit-card",
          key: "task",
          disabled: false,
        },
        {
          title: "Timesheets",
          icon: "mdi-credit-card",
          key: "timesheet",
          disabled: false,
        },
        
         {
          title: "Jobs",
          icon: "mdi-credit-card",
          key: "job",
          disabled: false,
        } ,
        
        {
          title: "Project Costings",
          icon: "mdi-credit-card",
          key: "costing",
          disabled: false,
        },
        {
          title: "Quotations",
          icon: "mdi-account-multiple",
          key: "quotation",
          disabled: false,
        },
        {
          title: "Purchase Orders",
          icon: "mdi-account-multiple",
          key: "purchase-order",
          disabled: false,
        },
        {
          title: "Contracts",
          icon: "mdi-account-multiple",
          key: "contract",
          disabled: false,
        },
        {
          title: "Invoices",
          icon: "mdi-account-multiple",
          key: "invoice",
          disabled: false,
        }, */
        {
          title: "History",
          icon: "mdi-credit-card",
          key: "history",
          disabled: false,
        },
      ],
      projectQuoteCosting: [
        {
          title: "Create Costing",
          value: "costing",
          icon: "mdi-credit-card",
        },
        {
          title: "Create Quotation",
          value: "quotation",
          icon: "mdi-account-multiple",
        },
      ],
      projectMoreAction: [],
    };
  },
  components: {
    // Milestones,
    Tasks,
    Attachments,
    Tickets,
    Contracts,
    Invoices,
    Quotations,
    QuotationOrder,
    CompanyContactPerson,
    PurchaseOrders,
    Timesheets,
    DetailTemplate,
    OverviewCreate,
    /*  CustomStatus, */
    ProjectCostings,
    InternalHistoryDetail,
    VisitListingTemplate,
    Barcode,
  },
  methods: {
    changeColor(type = "") {
      if (!type) return;
      if (type == "accept") {
        this.acceptDialog = true;
        this.isOppStatus = true;
        this.isAccepted = true;
        this.isRejected = false;
        this.activeTabClass = "green lighten-5 text-dark v-tab--active";
      }
      if (type == "reject") {
        this.acceptDialog = true;
        this.isOppStatus = true;
        this.isRejected = true;
        this.isAccepted = false;
        this.activeTabClass = "red lighten-5 text-dark v-tab--active";
      }
      if (type == "reopen") {
        this.isOppStatus = false;
        this.activeTabClass = "green darken-4 text-white v-tab--active";
      }
    },
    updateOpportunity() {
      let _this = this;

      _this.$router.push(
        _this.getDefaultRoute("opportunity.update", {
          params: {
            id:
              _this.opportunityArr && _this.opportunityArr
                ? _this.opportunityArr?.id
                : 0,
          },
        })
      );
    },
    refressData() {
      this.getOpportunity();
      this.getFile();
    },
    getStatus(status) {
      if (status == 1) {
        return "Not Started";
      } else if (status == 2) {
        return "In Progress";
      } else if (status == 3) {
        return "On Hold";
      } else if (status == 4) {
        return "Cancelled";
      } else if (status == 5) {
        return "Finished";
      }
    },
    getColor(status) {
      if (status == 1) {
        return "blue white--text";
      } else if (status == 2) {
        return "purple white--text";
      } else if (status == 3) {
        return "orange white--text";
      } else if (status == 4) {
        return "red white--text";
      } else if (status == 5) {
        return "green white--text";
      }
    },
    createMoreAction(action) {
      const _this = this;
      switch (action) {
        case "costing":
          // _this.updateOpportunityStatus(2);
          break;
        case "quotation":
          _this.$router.push(_this.getDefaultRoute("quotation.create", {}));
          break;
      }
    },
    updateMoreAction(action) {
      const _this = this;
      switch (action) {
        case "duplicate":
          _this.$router.push(
            _this.getDefaultRoute("opportunity.create", {
              query: {
                duplicate: _this.opportunityArr?.id,
                customer: _this.lodash.toSafeInteger(
                  _this.opportunityArr?.customer?.id
                ),
                property: _this.lodash.toSafeInteger(
                  _this.opportunityArr?.property?.id
                ),
              },
            })
          );
          break;
        case "edit":
          _this.$router.push(
            _this.getDefaultRoute("opportunity.update", {
              params: {
                id: _this.opportunityArr?.id,
              },
              query: {
                customer: _this.lodash.toSafeInteger(
                  _this.opportunityArr?.customer?.id
                ),
                property: _this.lodash.toSafeInteger(
                  _this.opportunityArr?.property?.id
                ),
              },
            })
          );
          break;
        case "mark_as_inprogress":
          _this.updateOpportunityStatus(2);
          break;
        case "mark_as_hold":
          _this.updateOpportunityStatus(3);
          break;
        case "mark_as_cancel":
          _this.updateOpportunityStatus(4);
          break;
        case "mark_as_finish":
          _this.updateOpportunityStatus(5);
          break;
        case "download_pdf":
          break;
        case "print":
          break;
      }
    },
    updateOpportunityStatus(status) {
      const _this = this;
      _this.$store
        .dispatch(PATCH, {
          url: "opportunity/" + _this.project + "/status",
          data: { status },
        })
        .then(() => {
          _this.getOpportunity();
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    getOpportunity() {
      const _this = this;
      _this.$store
        .dispatch(GET, {
          url: "opportunity/" + _this.opportunity,
        })
        .then(({ data }) => {
          _this.opportunityArr = data;
          _this.projectMoreAction = data.more_actions;
        })
        .catch((error) => {
          _this.logError(error);
          _this.goBack();
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    getFile() {
      const _this = this;
      _this.$store
        .dispatch(GET, {
          url: "project-file/" + _this.project,
        })
        .then(({ data }) => {
          _this.fileArr = data;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
  },
  mounted() {
    const _this = this;
    _this.getOpportunity();
    _this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Opportunity",
        route: "opportunity",
      },
      {
        title: "Detail",
      },
    ]);
  },
  created() {
    const _this = this;
    _this.opportunity = _this.lodash.toSafeInteger(_this.$route.params.id);
    if (!_this.opportunity || _this.opportunity <= 0) {
      _this.$router.go(-1);
    }

    if (_this.$route.query && _this.$route.query.tab) {
      _this.opportunityTab = _this.$route.query.tab;
    }
  },
  computed: {
    getPageTitle() {
      let result = [];
      if (this.lodash.isEmpty(this.opportunityArr) === false) {
        if (this.opportunityArr.name) {
          result.push(this.opportunityArr.name);
        }
        if (this.opportunityArr.barcode) {
          result.push(this.opportunityArr.barcode);
        }
      }
      return result.join(" • ");
    },
    opportunityTab: {
      set(val) {
        let query = { ...this.$route.query };
        query.tab = val;
        if (val != this.opportunityTab) {
          this.$router.push({ query });
        }
      },
      get() {
        return this.$route.query.tab || "opportunity";
      },
    },
  },
};
</script>
